
*, :after, :before {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', 'Amatic', sans-serif;
  font-weight: normal;
  font-style: normal;
  overflow-X: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  line-height: 1.3em
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 14px;
    color: #4f4f4f;
    font-family: 'Raleway', Sans-serif;
    margin: 0px;
}

a, a:focus, a:hover, a:visited {
  text-decoration: none;
}

a {
  background-color: transparent;
}

h2 {
    font-family: "Open Sans", Sans-serif;
    font-weight: 700;
    font-size: 21px;
    text-transform: uppercase;
}

h4 {
    color: #050505;
    font-family: 'Libre Baskerville';
    font-size: 40px;
    font-weight: 400;
}

h5 {
    font-family: "Open Sans", Sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    color: white;
    text-transform: uppercase;
}

.App {
    width: 100vw;
}
.btn-icon {
  vertical-align: sub;

}