.spinner-container {
    height: 100vh;
    width: 100vw;
    background-color:white;
    position: relative;
}

.lds-heart {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 40px 40px;
    background-image: url('../image/favicon.JPG');
    background-repeat: no-repeat;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}


@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}