.contact-form-container {
    width: 570px;
    padding: 8%;
}

.contact-image {
    background-image: url('../image/contactform.jpg');
    background-repeat:no-repeat;
    background-position: center;
    background-size:contain;
}

.error-message {
    font-size: 80%;
    color: red;
}