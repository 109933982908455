.subhero-container {
    height: 50vh;
    background-image: url('../image/cover2.jpg');
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
}

.subhero-container img {
    max-height: 100%;
}

.subhero {
    height: 100%;
}

.subhero div {
    height: 40%;
}
