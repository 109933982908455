.hero-container {
    background-image: url("../image/cover1.jpg");
    height: 90vh;
    width: 100vw;
    background-repeat:no-repeat;
    background-position: center;
    background-size:cover;
}

.content-container {
    height: 100%;
}

.content {
    height: 40vh;
}

.item2,
.item2 li {
    background-color: #f5f5f5;
}

.item2 .list-group-item {
    padding: 0.75rem 0;
}

@media only screen and (max-width: 576px) {
    .hero-container {
      background: none;
    }
  }