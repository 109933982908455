.feature-container {
    margin: 100px 0 50px 0;
}

.feature-item2 li {
    font-size: 14px;
}


.feature-list {
    border-bottom: solid 1px rgb(122, 122, 122);
}


@media (max-width: 768px) { 
    .feature-container {
        margin: 20px 0 30px 0;
    }
}

@media (max-width: 576px) {
    .feature-container {
        margin: 15px 0 10px 0;
    }
}


